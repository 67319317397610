import React from "react"
import { graphql, PageProps } from "gatsby"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import { sanitize } from "@helpers/helpers"
import { PrivacyPolicyDataType } from "@typings/requests/privacy-policy"
import "./privacy-policy.scss"

const PrivacyPolicy: React.FC<PageProps<PrivacyPolicyDataType>> = props => {
  const {
    allStrapiPrivacyPolicyPage: {
      nodes: [privacyPolicyData],
    },
  } = props.data
  const { title, description, metaImage, text, header, locale } =
    privacyPolicyData
  return (
    <>
      <SEO
        title={title}
        description={description}
        image={metaImage.localFile.publicURL}
        pathname={props.location.pathname}
        lang={locale}
      />
      <LayoutContainer
        className="privacy"
        location={props.location}
        lang="en"
        colorLangSwitcher="black"
      >
        <section className="container privacy__header">
          <h1
            className="privacy__title container__item text text--h1"
            dangerouslySetInnerHTML={{
              __html: sanitize(header),
            }}
          />
        </section>
        <section className="container">
          <div
            className="container__item privacy__info text"
            dangerouslySetInnerHTML={{
              __html: sanitize(text.data.text),
            }}
          />
        </section>
      </LayoutContainer>
    </>
  )
}

export const query = graphql`
  {
    allStrapiPrivacyPolicyPage(filter: { locale: { eq: "en" } }) {
      nodes {
        locale
        header
        description
        metaImage {
          localFile {
            publicURL
          }
        }
        text {
          data {
            text
          }
        }
        title
      }
    }
  }
`

export default PrivacyPolicy
